<template>
  <div>
    <div class="relative bg-yellow-100 bg-opacity-50">
      <img class="sm:hidden w-full" src="/img/msubmit.png" alt="Ask Us" />
      <div
        class="absolute inset-0 flex sm:items-center mt-4 sm:mt-0 justify-center"
      >
        <h2 class="font-bold text-3xl text-primary-orange">
          Submit a Question
        </h2>
      </div>
      <img class="hidden sm:block w-full" src="/img/about.png" alt="Ask Us" />
    </div>
    <div class="md:max-w-xl md:mx-auto px-6 md:px-0 py-12 space-y-4 text-sm">
      <div><i class="fas fa-envelope text-7xl text-primary-orange"></i></div>
      <p>
        Thank you for submitting a question! We will do our best to answer and
        add it to our website.
      </p>
      <p>
        For other concerns, know your rights
        <router-link to="/" class="underline font-medium text-primary-orange"
          >here</router-link
        >.
      </p>
      <p>Or</p>
      <div class="md:w-72">
        <Button class="w-full">Submit another Question</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Topics from "@/mixins/Topics";

import Button from "@/components/Button";
import AudioInput from "@/components/AudioInput";
import MultiSearch from "@/components/MultiSearch";
import SocialLinks from "@/components/SocialLinks";

export default {
  mixins: [Topics],
  components: {
    Button,
    AudioInput,
    MultiSearch,
    SocialLinks,
  },
  data: () => ({
    mode: "speech",
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style></style>
